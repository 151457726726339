
import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Board from 'react-trello'

const data = {
  lanes: [
    {
      id: 'lane1',
      title: 'Suggestions',
      label: '2/2',
      cards: [
        {id: 'Card1', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins', draggable: false},
        {id: 'Card2', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
      ]
    },
    {
      id: 'lane2',
      title: 'Contact',
      label: '0/0',
      cards: []
    },
    {
      id: 'lane3',
      title: 'Pending',
      label: '0/0',
      cards: [
        {id: 'Card3', title: 'John Snow', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
      ]
    },
    {
      id: 'lane4',
      title: 'Confirmed',
      label: '0/0',
      cards: []
    }
  ]
}

const project = {
  profiles: [
    {
      "id": "87fdf167-0f12-5a18-8cc4-0aecda4d531e",
      "fullName": "Alastair Stewart",
      "thumbnail": {
        "childImageSharp": {
          "fluid": {
            "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsSAAALEgHS3X78AAACOklEQVQ4y5WTiXKiUBBF+f//mJRR2UQWcUnMGDcWFRRQ2QR3/Yi58EbKGCdVU9VlwfOdvn27G+pwPP1X7A/H7e6w3e2SdEv9cO94OiO+Hh53e5B7kPEmfQ6fzpf94ZSkSI/L2Sshc819us1kozihngp6y3Xvc9DudBF4wCsSEk1CxpskjDbUd835wm2obUSz9YbAg9JoWbYD5YKM4k0YxdSDJv5otd8JVgR4tdnxgwjKsIqCQfp+RN238Xy52nMHOvc8MIQkq+ZkBjjTDGMkWq39Rxg37mFgxEJdaozGOvoXhHEA0g+85eon5YLMYFExzCkM+0G49oPlynfc5XfP6YMscslKE4Geo2ZCQnbhuP/sNm4TTJQaRBZ98nPS9daO66HGJ3O+XK9I/9kfYcjN9ttHrw+RJEl9P1ytYTUjcWLZ88ey8et6K+ggMVzNHXcytQYDzZzOblY9lGbZi+nMou5J7NBwpKNalIrZKHnxQl1m+Tqi1ekuHGh6OWkb5uQLPBxpYEh7M7eyiiyCqNTqMl8TqzSHc1Q0s2xzMtX0HM6HdIEZWWmhw6RPUk6iVTVB4moiywkMW3utMFh1yGqGiaCKJo/GBphsNoSUVUJCk+PrIKsMXyrTktzAImm6OdYMqtiQj999Mh4pLxgk3PKCxN7ICs2VXqssL2SyuoGF+wvjQ33v9qR8GTISVnOSFEzIcoV5KVVohocmykSDKGhmH+p2B1iUVOlGFlZpQlZZyP56KdMMB03EcKz/AdJO1YyHOJxLAAAAAElFTkSuQmCC",
            "aspectRatio": 1,
            "src": "/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png",
            "srcSet": "/static/639a7f5511122a90817ab971fe4266c4/15913/profile-placeholder.png 75w,\n/static/639a7f5511122a90817ab971fe4266c4/463ca/profile-placeholder.png 150w,\n/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png 300w,\n/static/639a7f5511122a90817ab971fe4266c4/8c740/profile-placeholder.png 450w,\n/static/639a7f5511122a90817ab971fe4266c4/44241/profile-placeholder.png 600w",
            "sizes": "(max-width: 300px) 100vw, 300px"
          }
        }
      }
    },
    {
      "id": "216feb8a-5dd3-5e20-9a31-acfea6eb8bdd",
      "fullName": "Alesha Dixon",
      "thumbnail": {
        "childImageSharp": {
          "fluid": {
            "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsSAAALEgHS3X78AAACOklEQVQ4y5WTiXKiUBBF+f//mJRR2UQWcUnMGDcWFRRQ2QR3/Yi58EbKGCdVU9VlwfOdvn27G+pwPP1X7A/H7e6w3e2SdEv9cO94OiO+Hh53e5B7kPEmfQ6fzpf94ZSkSI/L2Sshc819us1kozihngp6y3Xvc9DudBF4wCsSEk1CxpskjDbUd835wm2obUSz9YbAg9JoWbYD5YKM4k0YxdSDJv5otd8JVgR4tdnxgwjKsIqCQfp+RN238Xy52nMHOvc8MIQkq+ZkBjjTDGMkWq39Rxg37mFgxEJdaozGOvoXhHEA0g+85eon5YLMYFExzCkM+0G49oPlynfc5XfP6YMscslKE4Geo2ZCQnbhuP/sNm4TTJQaRBZ98nPS9daO66HGJ3O+XK9I/9kfYcjN9ttHrw+RJEl9P1ytYTUjcWLZ88ey8et6K+ggMVzNHXcytQYDzZzOblY9lGbZi+nMou5J7NBwpKNalIrZKHnxQl1m+Tqi1ekuHGh6OWkb5uQLPBxpYEh7M7eyiiyCqNTqMl8TqzSHc1Q0s2xzMtX0HM6HdIEZWWmhw6RPUk6iVTVB4moiywkMW3utMFh1yGqGiaCKJo/GBphsNoSUVUJCk+PrIKsMXyrTktzAImm6OdYMqtiQj999Mh4pLxgk3PKCxN7ICs2VXqssL2SyuoGF+wvjQ33v9qR8GTISVnOSFEzIcoV5KVVohocmykSDKGhmH+p2B1iUVOlGFlZpQlZZyP56KdMMB03EcKz/AdJO1YyHOJxLAAAAAElFTkSuQmCC",
            "aspectRatio": 1,
            "src": "/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png",
            "srcSet": "/static/639a7f5511122a90817ab971fe4266c4/15913/profile-placeholder.png 75w,\n/static/639a7f5511122a90817ab971fe4266c4/463ca/profile-placeholder.png 150w,\n/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png 300w,\n/static/639a7f5511122a90817ab971fe4266c4/8c740/profile-placeholder.png 450w,\n/static/639a7f5511122a90817ab971fe4266c4/44241/profile-placeholder.png 600w",
            "sizes": "(max-width: 300px) 100vw, 300px"
          }
        }
      }
    },
    {
      "id": "272576ad-bfb2-5289-939c-31829b1ccc49",
      "fullName": "Adrian Chiles",
      "thumbnail": {
        "childImageSharp": {
          "fluid": {
            "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsSAAALEgHS3X78AAACOklEQVQ4y5WTiXKiUBBF+f//mJRR2UQWcUnMGDcWFRRQ2QR3/Yi58EbKGCdVU9VlwfOdvn27G+pwPP1X7A/H7e6w3e2SdEv9cO94OiO+Hh53e5B7kPEmfQ6fzpf94ZSkSI/L2Sshc819us1kozihngp6y3Xvc9DudBF4wCsSEk1CxpskjDbUd835wm2obUSz9YbAg9JoWbYD5YKM4k0YxdSDJv5otd8JVgR4tdnxgwjKsIqCQfp+RN238Xy52nMHOvc8MIQkq+ZkBjjTDGMkWq39Rxg37mFgxEJdaozGOvoXhHEA0g+85eon5YLMYFExzCkM+0G49oPlynfc5XfP6YMscslKE4Geo2ZCQnbhuP/sNm4TTJQaRBZ98nPS9daO66HGJ3O+XK9I/9kfYcjN9ttHrw+RJEl9P1ytYTUjcWLZ88ey8et6K+ggMVzNHXcytQYDzZzOblY9lGbZi+nMou5J7NBwpKNalIrZKHnxQl1m+Tqi1ekuHGh6OWkb5uQLPBxpYEh7M7eyiiyCqNTqMl8TqzSHc1Q0s2xzMtX0HM6HdIEZWWmhw6RPUk6iVTVB4moiywkMW3utMFh1yGqGiaCKJo/GBphsNoSUVUJCk+PrIKsMXyrTktzAImm6OdYMqtiQj999Mh4pLxgk3PKCxN7ICs2VXqssL2SyuoGF+wvjQ33v9qR8GTISVnOSFEzIcoV5KVVohocmykSDKGhmH+p2B1iUVOlGFlZpQlZZyP56KdMMB03EcKz/AdJO1YyHOJxLAAAAAElFTkSuQmCC",
            "aspectRatio": 1,
            "src": "/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png",
            "srcSet": "/static/639a7f5511122a90817ab971fe4266c4/15913/profile-placeholder.png 75w,\n/static/639a7f5511122a90817ab971fe4266c4/463ca/profile-placeholder.png 150w,\n/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png 300w,\n/static/639a7f5511122a90817ab971fe4266c4/8c740/profile-placeholder.png 450w,\n/static/639a7f5511122a90817ab971fe4266c4/44241/profile-placeholder.png 600w",
            "sizes": "(max-width: 300px) 100vw, 300px"
          }
        }
      }
    }
  ]
}

const getContent = () => (
  <div>
    <div
      className="title is-size-5"
      // to={post.fields.slug}
      style={{
        margin: '-8px -8px 0 -8px',
        display: 'block',
        height: 124,
        backgroundImage: `url(/static/639a7f5511122a90817ab971fe4266c4/b8b58/profile-placeholder.png)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}></div>
      <div>
        Please drop comments here
        <input placeholder="Comments" />
      </div>
  </div>
)

const lanes = data.lanes.map(lane => {
  if(lane.id === 'lane1') {
    lane.cards = project.profiles.map(p => ({
      id: p.id,
      title: p.fullName,
      description: getContent(),
      label: '5 mins',
      // metadata: {sha: 'be312a1'} 
    }))
  }
  return lane;
})

export const ProjectTemplate = ({}) => {
  return (
    <div>
      <div style={{padding: '8px 16px'}}>
        <h3>Project Name</h3>
      </div>
      <div>
        <Board
          data={{ lanes }}
          style={{ background: '#fff' }}
        />
      </div>
    </div>
  )
}

ProjectTemplate.propTypes = {}

export default ProjectTemplate;

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
    }
  }
`
